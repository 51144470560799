<template>
    <div :class="['wit-file']" :id="id">
        <b-form-file v-model="internalValue" :placeholder="placeholder" :disabled="disabled" @input="emitInput">
            <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </b-form-file>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
        },
        id: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.internalValue = this.value
        },
    },
    created() {
        this.internalValue = this.value
    },
    data() {
        return {
            internalValue: null,
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.internalValue)
        },
    },
}
</script>

<style lang="scss"></style>
